/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 8px;
    height: 5px;

    color: #f6c30d;
}

/* Track */
::-webkit-scrollbar-track {
    background: black;
    width: 50%;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #f6c30d;
    height: 50px;
    width: 50px;
    border-radius: 10px;
}

.InsideScroll::-webkit-scrollbar {
    width: 8px;
}

.InsideScroll::-webkit-scrollbar-thumb {
    background: #f6c30d;
}

.InsideScroll::-webkit-scrollbar-track {
    background: #f6c30d77;
    border-left: 3.5px solid transparent;
    border-right: 3.5px solid transparent;
    background-clip: padding-box;
}

.InsideScrollApp::-webkit-scrollbar {
    width: 5px;
}

.InsideScrollApp::-webkit-scrollbar-thumb {
    background: #f6c30d;
}

.InsideScrollApp::-webkit-scrollbar-track {
    background: #f6c30d77;
    border-left: 2px solid #181818;
    border-right: 2px solid transparent;
    background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

model-viewer {
    width: 100%;
    height: 100%;
    outline: none;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

video {
    outline: none;
}

.userInput {
    outline: none !important;
}

#webgl-canvas {
    outline: none !important;
}

.tab-pane {
    display: None;
}

.tab-pane.active {
    display: block;
    height: 100%;
}

@responsive {
    .text-shine {
        text-shadow: 0 0 1px rgba(255, 255, 255, 0.8);
    }

    .text-primary-shine {
        text-shadow: 0 0 2px #f6c30d77;
    }

    .text-shadow {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .text-shadow-md {
        text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12),
            0 2px 4px rgba(0, 0, 0, 0.08);
    }

    .text-shadow-lg {
        text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11),
            0 5px 15px rgba(0, 0, 0, 0.08);
    }

    .text-shadow-none {
        text-shadow: none;
    }
}

:root {
    --index: 0;
    --side-navigation-item-height: 2rem;
}

*:nth-child(1) {
    --index: 0;
}
*:nth-child(2) {
    --index: 1;
}
*:nth-child(3) {
    --index: 2;
}
*:nth-child(4) {
    --index: 3;
}
*:nth-child(5) {
    --index: 4;
}
*:nth-child(6) {
    --index: 5;
}
*:nth-child(7) {
    --index: 6;
}
*:nth-child(8) {
    --index: 7;
}
*:nth-child(9) {
    --index: 8;
}
*:nth-child(10) {
    --index: 9;
}
*:nth-child(11) {
    --index: 10;
}
*:nth-child(12) {
    --index: 11;
}
*:nth-child(13) {
    --index: 12;
}
*:nth-child(14) {
    --index: 13;
}
*:nth-child(15) {
    --index: 14;
}
*:nth-child(16) {
    --index: 15;
}
*:nth-child(17) {
    --index: 16;
}
*:nth-child(18) {
    --index: 17;
}
*:nth-child(19) {
    --index: 18;
}
*:nth-child(20) {
    --index: 19;
}
*:nth-child(21) {
    --index: 20;
}
*:nth-child(22) {
    --index: 21;
}
*:nth-child(23) {
    --index: 22;
}
*:nth-child(24) {
    --index: 23;
}

body {
    @apply dark:text-white text-black;
}

img,
video {
    max-height: 100%;
}

.show {
    transform: translate(0, 0);
    transition: transform 0.2s, border-top-right-radius 0.2s,
        border-bottom-right-radius 0.2s;
}

.nav-link {
    height: 100%;
}

.mark-down a {
    color: #4a82af;
}

.c-w-40 {
    width: calc(100% - theme('spacing.40'));
}

.t-mint {
    top: calc(100% - 5rem);
}

.p-mint {
    padding: 0.1rem 0.2rem 0 0.2rem;
}

.c-w-results {
    width: calc(100% - 10rem);
}

.c-w-collection {
    width: calc(100% - 130px);
}

.collection-img {
    max-width: 1rem;
    max-height: 1rem;
}

.forward-button {
    top: calc(100% - 2rem);
    left: calc(100% - 2rem);
}

.back-button {
    top: calc(100% - 2rem);
    right: calc(100% - 2rem);
}

.error-note-size {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    max-width: 250px;
    max-height: 100px;
}

.c-size-mint {
    width: calc(100% - 0.6rem);
    top: calc(100% - 10.6rem);
}

.mint-info-width {
    width: fit-content;
}

.min-w-8 {
    min-width: 2rem;
}

.p-1000 {
    perspective: 1000px;
}

.flip-transform {
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-transform-rotate {
    transform: rotateY(180deg);
}

.bv-hidden {
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

.max-w-44 {
    max-width: 11rem;
}

.max-h-44 {
    max-height: 11rem;
}

.max-w-4 {
    max-width: 1rem;
}

.colletion-title-position {
    top: -190px;
    left: 60px;
}

.top-m1 {
    top: -0.1rem;
}

.right-m1 {
    right: -0.8rem;
}

.left-m1 {
    left: -0.8rem;
}

.left-popup {
    left: calc(50% - 15em);
}

.shadow {
    box-shadow: 5px 5px 20px -5px #000000aa;
}

.a-bg {
    background-color: #262626aa;
}

.b-1 {
    border: 1px solid #f6c30d44;
}

.left-error {
    left: calc(100% - 24em);
}

.search-settings-show {
    transform: translate(0, 0);
    transition: transform 0.2s, border-top-right-radius 0.2s,
        border-bottom-right-radius 0.2s;
}

.search-settings-hide {
    position: relative;
    transform: translate(-40px);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    transition: transform 0.2s, border-top-right-radius 0.2s,
        border-bottom-right-radius 0.2s;
}

.search-results-hide {
    width: calc(100% + 40px);
    position: relative;
    transition: width 0.2s;
}

.refresh-icon {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: reverse;
}

.red-filter {
    -webkit-filter: invert(40%) grayscale(100%) brightness(40%) sepia(100%)
        hue-rotate(-50deg) saturate(400%) contrast(2);
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg)
        saturate(300%) contrast(0.8);
    opacity: 0.4;
    filter: alpha(opacity=40);
    transition: all 0.5s;
}

.red-filter:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    opacity: 0.8;
    filter: alpha(opacity=80);
    transition: all 0.5s;
}

.top-sale-price {
    text-shadow: 0 0 20px #4a82af;
}

/* drawer */
.drawer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 650px;
    border-radius: 0;
}

.drawer-animated {
    transform: translateX(-100%);
}

.drawer-animated-slide-in {
    animation: slide-in 0.3s forwards;
}

@keyframes slide-in {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
}

/* remove left drawer background scroll */
.EZDrawer .EZDrawer__overlay {
    height: calc(100vh - 88px) !important;
}

/* .test-select select {
    width: 100px !important;
}

.test-select select option {
    width: 200px !important;
} */

/* Increase zIndex for the DateTimePicker's popper */
.MuiPickersPopper-root {
    z-index: 1301 !important;
}
/* Increase zIndex for the DateTimePicker's popper */
.MuiDialogContent-root {
    z-index: 9999 !important;
}

.splide {
    padding: 0 !important;
}

.splide__arrow svg {
    fill: none !important;
}

swiper-slide {
    width: min-content !important;
}

.blog-markdown h3 {
    @apply text-[24px] font-bold text-black dark:!text-white py-2;
}

.blog-markdown img {
    @apply w-full max-h-[450px] object-contain text-center py-4;
}

.blog-markdown p {
    @apply text-[16px] font-medium text-zinc-800 dark:!text-zinc-200 py-2;
}

.blog-markdown a {
    @apply text-[16px] font-medium text-zinc-800 dark:!text-zinc-200 py-2 underline underline-offset-1;
}
